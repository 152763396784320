/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { string, object } from 'prop-types';

const MobileIconBlock = ({
    imgSrc, linkHref, description, className, brandCode, classes,
}) => (
    linkHref && imgSrc
    && (
        <a href={linkHref} className={className} alt="mobile-icon-block" aria-label={description}>
            <img src={imgSrc} className={`${brandCode === '18F' && classes.flowersRadioIcon}`} alt={description} aria-hidden="true" />
            <span className={`${brandCode === '18F' && classes.flowersRadioText}`}>
                {description}
            </span>
        </a>
    )
);

MobileIconBlock.propTypes = {
    brandCode: string.isRequired,
    description: string.isRequired,
    linkHref: string.isRequired,
    imgSrc: string.isRequired,
    className: string.isRequired,
    classes: object.isRequired,
};

export default MobileIconBlock;
