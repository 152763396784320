/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import mbpLogger from 'mbp-logger';
import ResponsiveImage from '../../../GraphqlComponents/GraphqlCommonComponents/ResponsiveImage/ResponsiveImage';

const BACKGROUND_COLOR = '#efefef';

const styles = () => ({
    brandTabsStyle: {
        display: 'flex',
        backgroundColor: BACKGROUND_COLOR,
        listStyle: 'none',
        justifyContent: 'space-around',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        paddingLeft: '10px',
        paddingBottom: '15px',
        margin: 0,
    },

    brandTabStyle: {
        backgroundColor: BACKGROUND_COLOR,
        padding: '5px',
        borderBottom: 0,
        '&:hover': {
            paddingBottom: '3px',
            borderBottom: '2px solid #000',
        },
    },
    brandTabImageStyle: {
        height: '44px',
        width: '90%',
        maxWidth: '100px',
    },
});

function BrandTabs({ brands, currentBrandCode, classes }) {
    return (
        <ul className={classes.brandTabsStyle}>
            {brands.map((brandTab) => {
                if (brandTab.code === currentBrandCode || brandTab.show === false) {
                    return null;
                }

                let borderBottomColor = 'black';
                if (
                    brandTab.theme
          && brandTab.theme.palette
          && brandTab.theme.palette.primary
          && brandTab.theme.palette.primary.highlight
                ) {
                    borderBottomColor = brandTab.theme.palette.primary.highlight;
                } else {
                    mbpLogger.logWarning({
                        functionName: 'BrandTabs',
                        appName: process.env.npm_package_name,
                        module: 'mbp-header',
                        message: 'BrandTabs: theme highlight color not properly defined',
                    });
                }

                return (
                    <li key={brandTab.code} className={classes.brandTabStyle} style={{ borderBottomColor }}>
                        <a href={`/${brandTab.homeURL}`} id={brandTab.code} aria-hidden="true">
                            <ResponsiveImage
                                path={brandTab.brandTabIcon}
                                alt={`go to ${brandTab.description}`}
                                className={classes.brandTabImageStyle}
                                params={{
                                    desktop: `${brandTab.imageWidth}px`,
                                    tablet: `${brandTab.imageWidth}px`,
                                    mmobile: `${brandTab.imageWidth}px`,
                                }}
                            />
                        </a>
                    </li>
                );
            })}
        </ul>
    );
}

BrandTabs.propTypes = {
    brands: PropTypes.array.isRequired,
    currentBrandCode: PropTypes.string,
    classes: PropTypes.object.isRequired, // added by withStyles HOC
};

BrandTabs.defaultProps = {
    currentBrandCode: '',
};

export default withStyles(styles)(BrandTabs);
