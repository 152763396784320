/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { string, func } from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';

// search icon component with icon and fallback des text as search
const MobileSearchIconBlock = ({
    className, onSearchClick, description = 'search',
}) => (
    <button type="button" className={className} onClick={onSearchClick}>
        <SearchIcon />
        <span>
            {description}
        </span>
    </button>
);

MobileSearchIconBlock.defaultProps = {
    description: 'search',
    className: '',
};
MobileSearchIconBlock.propTypes = {
    className: string,
    description: string,
    onSearchClick: func.isRequired,
};

export default MobileSearchIconBlock;
