/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect } from 'react';
import { shape, string } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import mbpLogger from 'mbp-logger';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import { makeStyles } from '@material-ui/core/styles';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ShoppingCartFull from '@material-ui/icons/ShoppingCartOutlined';
import { getOrderId, getOrderStatus, getRecipients } from '../../../../../state/ducks/Common/Common-Selectors';
import { getCart } from '../../../../../state/ducks/Cart/Cart-Actions';
import { cartStatusCompleteList } from '../../../../helpers/common/helper';

const useStyles = makeStyles((theme) => ({
    badge: {
    // The border color match the background color.
        border: `6px solid ${theme.palette.pink1}`,
        color: theme.palette.white,
        backgroundColor: theme.palette.pink1,
        height: '18px',
        width: '19px',
        minWidth: '12px',
        fontSize: '10px',
        right: '4px',
    },
    badgeHD: {
        top: '2px',
        left: '-1px',
        // The border color match the background color.
        color: theme.palette.colorIconHeader,
        backgroundColor: theme.palette.white,
        border: '0',
        height: '12px',
        width: '15px',
        minWidth: '15px',
        padding: '0',
        fontSize: '10px',
        textAlign: 'center',
        borderRadius: '50%',
    },
    cartIcon: {
        color: theme.palette.grey4,
        fontSize: '28px',
        display: 'block',
    },
    cartLinkStyle: {
        margin: 0,
        textDecoration: 'none',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    labelButton: {
        display: 'block',
    },
    titleIcon: {
        fontSize: '10px',
        fontFamily: theme.typography.fontFamily,
        display: 'block',
        textTransform: 'uppercase',
        color: theme.palette.colorIconHeader,
        textAlign: 'center',
    },
    cartIconHD: {
        color: theme.palette.colorIconHeader,
        fontSize: '24px',
        display: 'block',
        padding: '0px 0px 0px 2px',
    },
}));

const getItemCount = (orderStatus, recipients) => {
    let itemCount = 0;

    try {
    // Ignore completed order data [STATUS = M]
        if (orderStatus && !cartStatusCompleteList.includes(orderStatus)) {
            Object.keys(recipients).forEach((recipient) => {
                if (recipients[recipient].cartItems) {
                    const { cartItems } = recipients[recipient];

                    Object.keys(cartItems).forEach((orderItem) => {
                        itemCount += parseInt(cartItems[orderItem].quantity, 10);
                    });
                }
            });
        }
    } catch (ex) {
        mbpLogger.logError({
            orderStatus,
            recipients,
            function: 'getItemCount',
            appName: process.env.npm_package_name,
            module: 'mbp-header',
            jsError: ex,
            message: 'getItemCount FAILED.',
        });
    }

    return itemCount;
};

const MobileCartLink = ({  brand }) => {
    const recipients = useSelector(getRecipients);
    const orderId = useSelector(getOrderId);
    const orderStatus = useSelector(getOrderStatus);
    const dispatch = useDispatch();
    const classes = useStyles();
    useEffect(() => {
        if (!orderStatus && orderId) {
            dispatch(getCart(orderId));
        }
    }, []);

    const itemCount = getItemCount(orderStatus, recipients) || 0;
    const MobileCartLinkBrands = ['HD', 'WLF', 'SY'];
    const isBrandHD = MobileCartLinkBrands.includes(brand.code);
    const cssCartIcon = isBrandHD ? classes.cartIconHD : classes.cartIcon;
    const cssBadge = isBrandHD ? classes.badgeHD : classes.badge;
    const pathToCart = itemCount ? `/checkout/cart/${orderId}` : '/checkout/cart/';

    return (
        <Link
            to={pathToCart}
            className={classes.cartLinkStyle}
            alt="Mobile Cart"
            data-test="header-mobile-cart-link"
        >
            <IconButton
                classes={{
                    label: classes.labelButton,
                }}
            >
                {itemCount ? (
                    <Badge
                        badgeContent={itemCount}
                        color="primary"
                        classes={{ badge: cssBadge }}
                        data-test="header-cart-badge"
                    >
                        {' '}
                        {/* do NOT change or remove data-test attribute */}
                        {isBrandHD ? (
                            <ShoppingCartFull className={cssCartIcon} />
                        ) : (
                            <ShoppingCartIcon className={cssCartIcon} />
                        )}
                    </Badge>
                ) : (
                    <ShoppingCartIcon className={cssCartIcon} />
                )}
                {isBrandHD && <span className={classes.titleIcon}>Cart</span>}
            </IconButton>
        </Link>
    );
};

MobileCartLink.propTypes = {
    brand: shape({
        code: string.isRequired,
    }).isRequired,
};

export default MobileCartLink;
